<template>
  <v-form @submit.prevent="submitResponse">
    <div>
      <p class="mb-5">
        The following rate information was collected at 25<stemble-latex
          class="no-text-transform"
          content="$\degree$"
        />C for this reaction
        <stemble-latex class="no-text-transform" content="$\ce{A2(g) + B(g) -> A2B(g)}$" />
      </p>
      <br />
      <v-simple-table>
        <thead>
          <th v-for="heading in rowNames" :key="heading" style="font-size: 14px">
            <stemble-latex :content="heading" />
          </th>
        </thead>
        <tbody class="mb-5">
          <tr>
            <td
              v-for="(option, index) in row1"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
          <tr>
            <td
              v-for="(option, index) in row2"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
          <tr>
            <td
              v-for="(option, index) in row3"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
          <tr>
            <td
              v-for="(option, index) in row4"
              :key="index"
              style="font-weight: bold; text-align: center"
            >
              {{ option }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <div class="mt-5">
      <label for="answerPartA">
        a) What is the reaction order in
        <stemble-latex class="no-text-transform" content="$\ce{A2}$" />?
      </label>
      <calculation-input
        v-model="inputs.answerPartA"
        prepend-text="$\text{Order in }\ce{A2}:$"
        class="mb-5"
      />
    </div>
    <div>
      <label for="answerPartB">
        b) What is the reaction order in
        <stemble-latex class="no-text-transform" content="$\ce{B}$" /> ?
      </label>
      <calculation-input
        v-model="inputs.answerPartB"
        prepend-text="$\text{Order in B:}$"
        class="mb-5"
      />
    </div>
    <div>
      <label for="answerPartC"> c) Write the rate equation (rate law) for this reaction. </label>
      <v-textarea
        v-model="inputs.answerPartC"
        label="Rate equation"
        outlined
        rows="1"
        row-height="2"
        no-resize
      />
    </div>
    <div>
      <label for="answerPartD"> d) Calculate the rate constant for this reaction. </label>
      <calculation-input v-model="inputs.answerPartD" prepend-text="$\text{k}:$" class="mb-5" />
    </div>
    <div>
      <label for="answerPartE">
        e) What are the units of the rate constant for this reaction?
      </label>
      <v-textarea
        v-model="inputs.answerPartE"
        label="Units of k"
        outlined
        rows="1"
        row-height="2"
        no-resize
      />
    </div>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput';
import {submitResponse} from '@/tasks/api/task-responses';

export default {
  name: 'OttawaGotoHW7Q3',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        answerPartA: null,
        answerPartB: null,
        answerPartC: null,
        answerPartD: null,
        answerPartE: null,
      },
      rowNames: [
        '$\\textbf{p}_{\\textbf{A}_\\textbf{2}}$ (kPa)',
        '$\\textbf{p}_\\textbf{B}$ (kPa)',
        'Initial rate (kPa/min)',
      ],
      row1: ['1.60', '1.84', '0.138'],
      row2: ['3.21', '1.84', '0.552'],
      row3: ['3.21', '0.918', '0.276'],
      row4: ['1.60', '0.918', '0.069'],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    methods: {submitResponse},
  },
};
</script>
